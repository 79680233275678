import { useMutation } from '@apollo/client';
import { ErrorCode } from '@evoko/api';
import { Form } from '@evoko/components';
import { alphanumericRegex, isKeyOfObject } from '@evoko/utils';
import { ChevronLeft } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import type {
  JoinOrganizationMutation,
  JoinOrganizationMutationVariables,
} from '../../../generated/graphql';
import { M_JOIN_ORGANIZATION } from '../../../graphql';
import locale from '../../../locale';

const LL = locale.pages.profile.joinOrganization;

const domainFormErrors = {
  [ErrorCode.NotFound]: LL.domain.errors.notFound,
  [ErrorCode.AlreadyMember]: LL.domain.errors.alreadyMember,
  [ErrorCode.Banned]: LL.domain.errors.banned,
  [ErrorCode.Pending]: LL.domain.errors.pending,
  [ErrorCode.VerifyEmail]: LL.domain.errors.verifyEmail,
};

export function JoinOrganization() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitted },
  } = useForm({ mode: 'onChange', defaultValues: { domain: '' } });

  const [joinOrganization, { loading }] = useMutation<
    JoinOrganizationMutation,
    JoinOrganizationMutationVariables
  >(M_JOIN_ORGANIZATION, {
    refetchQueries: ['Profile'],
    onCompleted: () => {
      navigate('..');
    },
    onError: ({ graphQLErrors }) => {
      for (const { extensions } of graphQLErrors) {
        const code = extensions?.code;

        if (typeof code === 'string' && isKeyOfObject(code, domainFormErrors)) {
          setError('domain', { message: domainFormErrors[code] });
          return;
        }
      }

      enqueueSnackbar(LL.alerts.failure, { variant: 'error' });
    },
  });

  const onSubmit = handleSubmit(async ({ domain }) => {
    await joinOrganization({ variables: { domain } });
  });

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={{ sm: 'auto' }}>
      <Helmet>
        <title>{LL.title}</title>
      </Helmet>
      <Box>
        <IconButton
          component={Link}
          to=".."
          aria-label={locale.pages.profile.title}
        >
          <ChevronLeft fontSize="large" />
        </IconButton>
      </Box>
      <Typography variant="h1">{LL.title}</Typography>
      <Form onSubmit={onSubmit}>
        <Controller
          name="domain"
          control={control}
          rules={{
            required: LL.domain.errors.required,
            pattern: {
              value: alphanumericRegex,
              message: LL.domain.errors.alphanumericValidation,
            },
          }}
          render={({ field, fieldState: { isTouched, error } }) => (
            <TextField
              {...field}
              label={LL.domain.label}
              variant="outlined"
              margin="dense"
              fullWidth
              disabled={loading}
              error={!!error && (isTouched || isSubmitted)}
              helperText={
                ((isTouched || isSubmitted) && error?.message) ||
                LL.domain.helperText
              }
            />
          )}
        />
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          loading={loading}
          disabled={!isValid}
        >
          {LL.submitButton}
        </LoadingButton>
      </Form>
    </Box>
  );
}
