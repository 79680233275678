export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  Duration: { input: number; output: number; }
  Map: { input: any; output: any; }
  TimeOfDay: { input: number; output: number; }
};

export type ActivateOverviewCodePayload = {
  __typename?: 'ActivateOverviewCodePayload';
  expiresIn: Scalars['Duration']['output'];
  userCode: Scalars['String']['output'];
};

export type AddBookingInput = {
  checkedIn?: InputMaybe<Scalars['DateTime']['input']>;
  deskId: Scalars['ID']['input'];
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
  userEmail?: InputMaybe<Scalars['String']['input']>;
  /** If userID and userEmail is not set, an anonymous booking will be created */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddBuildingInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  openHours?: InputMaybe<WeeklyOpenHoursInput>;
  overrideOpenHours?: InputMaybe<Scalars['Boolean']['input']>;
  timePresentation?: InputMaybe<TimePresentation>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type AddDeskInput = {
  deskType: DeskType;
  floorId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  presenceDetection?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddFirmwareInput = {
  channels?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** @deprecated no longer used */
  hash?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type AddFloorInput = {
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type AddInvitationInput = {
  email: Scalars['String']['input'];
  role: Role;
};

export type AddLicenseInput = {
  desks: Scalars['Int']['input'];
  duration: Scalars['Int']['input'];
  refId?: InputMaybe<Scalars['ID']['input']>;
  rooms: Scalars['Int']['input'];
};

export type AddOrganizationMessageInput = {
  canBeClosed: Scalars['Boolean']['input'];
  content: Scalars['String']['input'];
  hideAt?: InputMaybe<Scalars['DateTime']['input']>;
  organizations?: InputMaybe<Array<Scalars['ID']['input']>>;
  showAt?: InputMaybe<Scalars['DateTime']['input']>;
  showToAllOrganizations: Scalars['Boolean']['input'];
  type: OrganizationMessageType;
};

export type AddOverviewInput = {
  columnCount: Scalars['Int']['input'];
  floorId?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  showDateTime: Scalars['Boolean']['input'];
  showLogo: Scalars['Boolean']['input'];
  tiles?: InputMaybe<Array<InputMaybe<AddTileInput>>>;
  type: OverviewType;
};

export type AddRoomBookingInput = {
  attendees?: InputMaybe<Array<AttendeeInput>>;
  /** `checkedIn` is not supported when creating space connect bookings. */
  checkedIn?: InputMaybe<Scalars['DateTime']['input']>;
  endTime: Scalars['DateTime']['input'];
  roomId: Scalars['ID']['input'];
  startTime: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  /**
   * If userId is omitted or `null` the booking is an anonymous booking. Currently,
   * anonymous booking is not implemented for organizations linked with space
   * connect.
   */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddRoomInput = {
  capacity?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  floorId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  resources?: InputMaybe<Array<RoomResource>>;
};

export type AddTileInput = {
  directionIndicator?: InputMaybe<Direction>;
  floorId?: InputMaybe<Scalars['ID']['input']>;
  hideBookingInformation?: InputMaybe<Scalars['Boolean']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  type: TileType;
};

export type Attendee = {
  __typename?: 'Attendee';
  company?: Maybe<Scalars['String']['output']>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type AttendeeInput = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AuthenticatePayload = {
  __typename?: 'AuthenticatePayload';
  accessToken: Scalars['String']['output'];
  /** Duration until the accessToken expires */
  expiresIn: Scalars['Duration']['output'];
  refreshToken: Scalars['String']['output'];
  subject: Subject;
  /** @deprecated use short lived `accessToken` */
  token: Scalars['String']['output'];
  /** @deprecated use `subject` */
  user?: Maybe<User>;
};

export type Availability = {
  __typename?: 'Availability';
  from: Scalars['DateTime']['output'];
  until: Scalars['DateTime']['output'];
};

export type AvailableFilter = {
  closeMatches?: InputMaybe<Scalars['Boolean']['input']>;
  from: Scalars['DateTime']['input'];
  until: Scalars['DateTime']['input'];
};

export type Booking = {
  __typename?: 'Booking';
  arrivedAt?: Maybe<Scalars['DateTime']['output']>;
  canceled?: Maybe<Scalars['DateTime']['output']>;
  checkedIn?: Maybe<Scalars['DateTime']['output']>;
  checkedOut?: Maybe<Scalars['DateTime']['output']>;
  desk: Desk;
  endTime: Scalars['DateTime']['output'];
  focusMode: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  skipAwayFromDeskReminder: Scalars['Boolean']['output'];
  skipLateArrivalReminder: Scalars['Boolean']['output'];
  startTime: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userDeleted: Scalars['Boolean']['output'];
};


export type BookingEndTimeArgs = {
  tz?: InputMaybe<Scalars['String']['input']>;
};


export type BookingStartTimeArgs = {
  tz?: InputMaybe<Scalars['String']['input']>;
};

export type BookingEdge = {
  __typename?: 'BookingEdge';
  node: Booking;
};

export type BookingEvent = {
  __typename?: 'BookingEvent';
  added?: Maybe<Booking>;
  deleted?: Maybe<Booking>;
  updated?: Maybe<Booking>;
};

export type BookingFilter = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  deskId?: InputMaybe<Scalars['ID']['input']>;
  /** onlyActiveBookings filters out canceled and checked out bookings (and bookings where the user has been deleted) */
  onlyActiveBookings?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  timeWindow?: InputMaybe<TimeWindow>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type BookingOrder = {
  desc?: Scalars['Boolean']['input'];
  field: BookingOrderField;
};

export const BookingOrderField = {
  CreatedAt: 'CREATED_AT',
  DeskName: 'DESK_NAME',
  EndTime: 'END_TIME',
  OrganizationName: 'ORGANIZATION_NAME',
  StartTime: 'START_TIME',
  UpdatedAt: 'UPDATED_AT',
  UserName: 'USER_NAME'
} as const;

export type BookingOrderField = typeof BookingOrderField[keyof typeof BookingOrderField];
export type BookingsConnection = {
  __typename?: 'BookingsConnection';
  bookings: Array<Booking>;
  edges: Array<BookingEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Building = {
  __typename?: 'Building';
  address?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  floors: Array<Floor>;
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  openHours: WeeklyOpenHours;
  overrideOpenHours: Scalars['Boolean']['output'];
  timePresentation?: Maybe<TimePresentation>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type BuildingEdge = {
  __typename?: 'BuildingEdge';
  node: Building;
};

export type BuildingFilter = {
  hasUnassignedFloorplanDesks?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if `name` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type BuildingOrder = {
  desc?: Scalars['Boolean']['input'];
  field: BuildingOrderField;
};

export const BuildingOrderField = {
  CreatedAt: 'CREATED_AT',
  Name: 'NAME',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type BuildingOrderField = typeof BuildingOrderField[keyof typeof BuildingOrderField];
export type BuildingsConnection = {
  __typename?: 'BuildingsConnection';
  buildings: Array<Building>;
  edges: Array<BuildingEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Channel = {
  __typename?: 'Channel';
  firmwares: Array<Firmware>;
  id: Scalars['ID']['output'];
  latestFirmware?: Maybe<Firmware>;
  name: Scalars['String']['output'];
};

export type CreateDevicePayload = {
  __typename?: 'CreateDevicePayload';
  device: Device;
  token: Scalars['String']['output'];
};

export type CreateOrganizationInput = {
  domain: Scalars['String']['input'];
  /** @deprecated use ola */
  eula?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ola: Scalars['String']['input'];
};

export type CreateSpaceConnectOrganizationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  administratorEmail: Scalars['String']['input'];
  administratorName?: InputMaybe<Scalars['String']['input']>;
  administratorPhone?: InputMaybe<Scalars['String']['input']>;
  domains: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Desk = {
  __typename?: 'Desk';
  /** @deprecated use currentBooking */
  activeBooking?: Maybe<Booking>;
  /** Contains the available timespan for this desk within the requested period. */
  available?: Maybe<Availability>;
  bookingCount: Scalars['Int']['output'];
  /** @deprecated this query will be removed */
  bookings: Array<Booking>;
  createdAt: Scalars['DateTime']['output'];
  /** The currently active booking, if any */
  currentBooking?: Maybe<Booking>;
  deskType: DeskType;
  floor?: Maybe<Floor>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The next upcoming booking, if any */
  nextBooking?: Maybe<Booking>;
  orgId: Scalars['ID']['output'];
  /** If true, the presence detection sensor will be enabled on the device */
  presenceDetection: Scalars['Boolean']['output'];
  settings: DeskBookingSettings;
  /**
   * Time presentation used for this desk. The value is picked from the building
   * with the organization default as fallback.
   */
  timePresentation: TimePresentation;
  /**
   * Time zone of this desk. The time zone is picked from the building with
   * the organization default as fallback.
   */
  timeZone: Scalars['String']['output'];
  /** Time offset from UTC in seconds */
  timeZoneOffset: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type DeskBookingsArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  until?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeskBookingSettings = {
  __typename?: 'DeskBookingSettings';
  /**
   * If the user has checked in, but has been absent from the desk for this duration,
   * a notice is pushed to the user.
   */
  absenceReminderDelay: Scalars['Duration']['output'];
  /** @deprecated use requireIdentForBooking */
  allowAnonymousBookings: Scalars['Boolean']['output'];
  /** If true, the desk device will automatically check-in a current booking on presence detection */
  autoCheckInOnPresence: Scalars['Boolean']['output'];
  /** @deprecated use autoCheckInOnPresence */
  automaticCheckIn: Scalars['Boolean']['output'];
  /** @deprecated use absenceReminderDelay */
  awayFromDeskReminderDelay: Scalars['Duration']['output'];
  /** @deprecated use daysInAdvanceLimit */
  bookingInAdvanceLimit: Scalars['Int']['output'];
  /** @deprecated use requireIdentForCheckIn */
  checkInWithTag: Scalars['Boolean']['output'];
  /** @deprecated use requireIdentForUpdating */
  checkOutWithTag: Scalars['Boolean']['output'];
  /** How many calendar days in advance you may place a booking */
  daysInAdvanceLimit: Scalars['Int']['output'];
  /** Offset for `daysInAdvanceLimit`. */
  daysInAdvanceLimitOffset: Scalars['TimeOfDay']['output'];
  /** The max booking duration limit */
  durationLimit: Scalars['Duration']['output'];
  /** Period of time, before start of booking, check-in is allowed. */
  earlyCheckIn: Scalars['Duration']['output'];
  /** @deprecated use requireIdentForUpdating */
  extendWithTag: Scalars['Boolean']['output'];
  /** The initial/default booking duration */
  initialDuration: Scalars['Duration']['output'];
  /** If the user has not showed up after this delay, a late-arrival notice is pushed to the user */
  lateArrivalReminderDelay: Scalars['Duration']['output'];
  /** @deprecated use durationLimit */
  maxBookingPeriod: Scalars['Duration']['output'];
  /** If true, the booker of a booking is private for all bookings. */
  privateOnly: Scalars['Boolean']['output'];
  /** If true, user are allowed to check-in using qr code in the app. */
  qrCodeCheckIn: Scalars['Boolean']['output'];
  /** If true, users are allowed to check-in remotely using the app */
  remoteCheckIn: Scalars['Boolean']['output'];
  /**
   * If true, the desk device will require identification with RFID tag to allow
   * the user to book the desk.
   */
  requireIdentForBooking: Scalars['Boolean']['output'];
  /** If true, the user must identify with RFID tag to check in on the desk device */
  requireIdentForCheckIn: Scalars['Boolean']['output'];
  /** If true, the user must identify with RFID tag to extend or check-out a booking */
  requireIdentForUpdating: Scalars['Boolean']['output'];
};

export type DeskBookingSettingsInput = {
  absenceReminderDelay?: InputMaybe<Scalars['Duration']['input']>;
  autoCheckInOnPresence?: InputMaybe<Scalars['Boolean']['input']>;
  daysInAdvanceLimit?: InputMaybe<Scalars['Int']['input']>;
  daysInAdvanceLimitOffset?: InputMaybe<Scalars['TimeOfDay']['input']>;
  durationLimit?: InputMaybe<Scalars['Duration']['input']>;
  earlyCheckIn?: InputMaybe<Scalars['Duration']['input']>;
  initialDuration?: InputMaybe<Scalars['Duration']['input']>;
  lateArrivalReminderDelay?: InputMaybe<Scalars['Duration']['input']>;
  /** @deprecated use `UpdateOrganizationInput.bookingSettings.privateOnly` */
  privateOnly?: InputMaybe<Scalars['Boolean']['input']>;
  qrCodeCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  remoteCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForBooking?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForUpdating?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeskEdge = {
  __typename?: 'DeskEdge';
  node: Desk;
};

export type DeskFilter = {
  available?: InputMaybe<AvailableFilter>;
  bookingCountTimeWindow?: InputMaybe<TimeWindow>;
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  excludeDesksAssignedToFloor?: InputMaybe<Scalars['Boolean']['input']>;
  floors?: InputMaybe<Array<Scalars['ID']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type DeskOrder = {
  /** @deprecated use `field` */
  column?: InputMaybe<DeskOrderField>;
  desc?: Scalars['Boolean']['input'];
  field?: InputMaybe<DeskOrderField>;
};

export const DeskOrderField = {
  BookingCount: 'BOOKING_COUNT',
  CreatedAt: 'CREATED_AT',
  LocationName: 'LOCATION_NAME',
  Name: 'NAME',
  PresenceDetection: 'PRESENCE_DETECTION',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type DeskOrderField = typeof DeskOrderField[keyof typeof DeskOrderField];
export type DeskStats = {
  __typename?: 'DeskStats';
  booked: Scalars['Int']['output'];
  occupied: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export const DeskType = {
  Hot: 'HOT',
  Hotel: 'HOTEL'
} as const;

export type DeskType = typeof DeskType[keyof typeof DeskType];
export type DesksConnection = {
  __typename?: 'DesksConnection';
  desks: Array<Desk>;
  edges: Array<DeskEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Device = {
  __typename?: 'Device';
  /** True if the device have connected successfully at least once to the backend */
  activated: Scalars['Boolean']['output'];
  /**
   * Weekly schedule where the device is considered to be "active", i.e. the office
   * is open and there is likely people using the device.
   */
  activeHours: WeeklyOpenHours;
  /**
   * Contains meta data about the firmware assigned to this device.
   * This should *not* be used by the device to know which firmware it
   * should upgrade to. Instead, devices should use the `nextFirmware` field.
   */
  assignedFirmware?: Maybe<Firmware>;
  /** The assigned firmware channel */
  channel?: Maybe<Channel>;
  createdAt: Scalars['DateTime']['output'];
  /** The assigned desk */
  desk?: Maybe<Desk>;
  /** The assigned desk ID */
  deskId?: Maybe<Scalars['ID']['output']>;
  /** Flash ID of the device */
  flashId?: Maybe<Scalars['String']['output']>;
  /** Identifies a unique device in backend. Not related to the hardware ID. */
  id: Scalars['ID']['output'];
  /** Language of the device. Falls back to organization default. */
  language: Scalars['String']['output'];
  /** MAC address of the device (Wifi) */
  mac?: Maybe<Scalars['String']['output']>;
  /**
   * Contains the next firmware in the upgrade chain for this device.
   * In the normal case this is the same as assignedFirmware, but when
   * a device must do some intermediate upgrades before reaching the
   * target version, this firmware will show the next firmware it
   * can/should upgrade to depending on the extra information suppplied
   * by the device
   */
  nextFirmware?: Maybe<Firmware>;
  orgId: Scalars['ID']['output'];
  orgName: Scalars['String']['output'];
  /** The assigned room */
  room?: Maybe<Room>;
  /** The assigned room ID */
  roomId?: Maybe<Scalars['ID']['output']>;
  /** Evoko serial for the specific device */
  serial?: Maybe<Scalars['String']['output']>;
  /** Current status as reported by the device */
  status?: Maybe<DeviceStatus>;
  /** Timezone of the device. Falls back to organization default. */
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type DeviceNextFirmwareArgs = {
  fwrev?: InputMaybe<Scalars['String']['input']>;
  hwrev?: InputMaybe<Scalars['String']['input']>;
  pubkey?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceEdge = {
  __typename?: 'DeviceEdge';
  node: Device;
};

export type DeviceFilter = {
  channels?: InputMaybe<Array<Scalars['ID']['input']>>;
  onlyActivated?: InputMaybe<Scalars['Boolean']['input']>;
  organizations?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Matches search term against `serial` and `desk.name`. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceInput = {
  deskId?: InputMaybe<Scalars['ID']['input']>;
  mac?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceOrder = {
  desc?: Scalars['Boolean']['input'];
  field: DeviceOrderField;
};

export const DeviceOrderField = {
  AssignedFirmware: 'ASSIGNED_FIRMWARE',
  ChannelName: 'CHANNEL_NAME',
  CreatedAt: 'CREATED_AT',
  DeskName: 'DESK_NAME',
  Mac: 'MAC',
  OrganizationName: 'ORGANIZATION_NAME',
  RoomName: 'ROOM_NAME',
  Serial: 'SERIAL',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type DeviceOrderField = typeof DeviceOrderField[keyof typeof DeviceOrderField];
export type DeviceStats = {
  __typename?: 'DeviceStats';
  total: Scalars['Int']['output'];
};

export type DeviceStatus = {
  __typename?: 'DeviceStatus';
  /** Current firmware version as reported by the device (semver) */
  firmware?: Maybe<Scalars['String']['output']>;
  /** Presence detected by the desk */
  presence?: Maybe<Scalars['Boolean']['output']>;
  /** Current temperature of the device in Celsius or Kelvin (TBD) */
  temperature?: Maybe<Scalars['Float']['output']>;
  /** Timestamp of the last status message received from device */
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  /** Uptime in seconds */
  uptime?: Maybe<Scalars['Int']['output']>;
};

export type DeviceStatusInput = {
  firmware?: InputMaybe<Scalars['String']['input']>;
  presence?: InputMaybe<Scalars['Boolean']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  uptime?: InputMaybe<Scalars['Int']['input']>;
};

export type DevicesConnection = {
  __typename?: 'DevicesConnection';
  devices: Array<Device>;
  edges: Array<DeviceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export const Direction = {
  East: 'EAST',
  North: 'NORTH',
  Northeast: 'NORTHEAST',
  Northwest: 'NORTHWEST',
  South: 'SOUTH',
  Southeast: 'SOUTHEAST',
  Southwest: 'SOUTHWEST',
  West: 'WEST'
} as const;

export type Direction = typeof Direction[keyof typeof Direction];
export type DomainInfo = {
  __typename?: 'DomainInfo';
  domain: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Firmware = {
  __typename?: 'Firmware';
  channels: Array<Channel>;
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated no longer used */
  hash?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type FirmwareEdge = {
  __typename?: 'FirmwareEdge';
  node: Firmware;
};

export type FirmwareFilter = {
  /** Only returns firmware where `version` or `channels.name` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type FirmwareOrder = {
  desc?: Scalars['Boolean']['input'];
  field: FirmwareOrderField;
};

export const FirmwareOrderField = {
  CreatedAt: 'CREATED_AT',
  UpdatedAt: 'UPDATED_AT',
  Url: 'URL',
  Version: 'VERSION'
} as const;

export type FirmwareOrderField = typeof FirmwareOrderField[keyof typeof FirmwareOrderField];
export type FirmwaresConnection = {
  __typename?: 'FirmwaresConnection';
  edges: Array<FirmwareEdge>;
  firmwares: Array<Firmware>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Floor = {
  __typename?: 'Floor';
  building: Building;
  createdAt: Scalars['DateTime']['output'];
  deskStats: DeskStats;
  floorplan?: Maybe<Floorplan>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FloorEdge = {
  __typename?: 'FloorEdge';
  node: Floor;
};

export type FloorFilter = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  hasUnassignedFloorplanDesks?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if `Floor.name` or `Floor.building.name` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type FloorOrder = {
  desc?: Scalars['Boolean']['input'];
  field: FloorOrderField;
};

export const FloorOrderField = {
  BuildingName: 'BUILDING_NAME',
  CreatedAt: 'CREATED_AT',
  DeskStatsTotal: 'DESK_STATS_TOTAL',
  Name: 'NAME',
  Position: 'POSITION',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type FloorOrderField = typeof FloorOrderField[keyof typeof FloorOrderField];
export type Floorplan = {
  __typename?: 'Floorplan';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  unassignedDeskCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FloorsConnection = {
  __typename?: 'FloorsConnection';
  edges: Array<FloorEdge>;
  floors: Array<Floor>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type IdTag = {
  __typename?: 'IDTag';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  tag: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type IdTagEdge = {
  __typename?: 'IDTagEdge';
  node: IdTag;
};

export type IdTagOrder = {
  desc?: Scalars['Boolean']['input'];
  field: IdTagOrderField;
};

export const IdTagOrderField = {
  CreatedAt: 'CREATED_AT',
  Name: 'NAME',
  Tag: 'TAG',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type IdTagOrderField = typeof IdTagOrderField[keyof typeof IdTagOrderField];
export type IdTagsConnection = {
  __typename?: 'IDTagsConnection';
  edges: Array<IdTagEdge>;
  idTags: Array<IdTag>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Invitation = {
  __typename?: 'Invitation';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  expiresAt: Scalars['DateTime']['output'];
  membership?: Maybe<Membership>;
  orgDomain: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  orgLogoUrl?: Maybe<Scalars['String']['output']>;
  orgName: Scalars['String']['output'];
  role: Role;
  status: InvitationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvitationEdge = {
  __typename?: 'InvitationEdge';
  node: Invitation;
};

export type InvitationFilter = {
  /** Checks if `email` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InvitationStatus>>;
};

export type InvitationOrder = {
  desc?: Scalars['Boolean']['input'];
  field: InvitationOrderField;
};

export const InvitationOrderField = {
  CreatedAt: 'CREATED_AT',
  Email: 'EMAIL',
  ExpiresAt: 'EXPIRES_AT',
  Role: 'ROLE',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type InvitationOrderField = typeof InvitationOrderField[keyof typeof InvitationOrderField];
export const InvitationStatus = {
  Accepted: 'ACCEPTED',
  Declined: 'DECLINED',
  Expired: 'EXPIRED',
  Pending: 'PENDING'
} as const;

export type InvitationStatus = typeof InvitationStatus[keyof typeof InvitationStatus];
export type InvitationsConnection = {
  __typename?: 'InvitationsConnection';
  edges: Array<InvitationEdge>;
  invitations: Array<Invitation>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type License = {
  __typename?: 'License';
  activatesAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  desks: Scalars['Int']['output'];
  duration: Scalars['Int']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  organization?: Maybe<Organization>;
  refId?: Maybe<Scalars['ID']['output']>;
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  rooms: Scalars['Int']['output'];
  trial: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LicenseEdge = {
  __typename?: 'LicenseEdge';
  node: License;
};

export type LicenseFilter = {
  expiresAt?: InputMaybe<TimeWindow>;
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LicenseOrder = {
  desc?: Scalars['Boolean']['input'];
  field: LicenseOrderField;
};

export const LicenseOrderField = {
  ActivatesAt: 'ACTIVATES_AT',
  CreatedAt: 'CREATED_AT',
  Desks: 'DESKS',
  Duration: 'DURATION',
  ExpiresAt: 'EXPIRES_AT',
  RefId: 'REF_ID',
  RevokedAt: 'REVOKED_AT',
  Rooms: 'ROOMS',
  Trial: 'TRIAL',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type LicenseOrderField = typeof LicenseOrderField[keyof typeof LicenseOrderField];
export type LicenseStats = {
  __typename?: 'LicenseStats';
  totalDesks: Scalars['Int']['output'];
  totalRooms: Scalars['Int']['output'];
  trialRedeemed: Scalars['Boolean']['output'];
  usedDesks: Scalars['Int']['output'];
  usedRooms: Scalars['Int']['output'];
};

export type LicensesConnection = {
  __typename?: 'LicensesConnection';
  edges: Array<LicenseEdge>;
  licenses: Array<License>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LogInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

export type Membership = {
  __typename?: 'Membership';
  bookings: BookingsConnection;
  createdAt: Scalars['DateTime']['output'];
  orgId: Scalars['ID']['output'];
  orgLogoUrl?: Maybe<Scalars['String']['output']>;
  /** Name of the organization. Does not require an active membership. */
  orgName: Scalars['String']['output'];
  /**
   * The organization the user is a member of. May be null if
   * the user does not have access to the organization.
   */
  organization?: Maybe<Organization>;
  role: Role;
  roomBookings: RoomBookingsConnection;
  spaceConnectEmail?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['ID']['output'];
};


export type MembershipBookingsArgs = {
  filter?: InputMaybe<MembershipBookingFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type MembershipRoomBookingsArgs = {
  filter?: InputMaybe<MembershipRoomBookingFilter>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type MembershipBookingFilter = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  deskId?: InputMaybe<Scalars['ID']['input']>;
  timeWindow?: InputMaybe<TimeWindow>;
};

export type MembershipEdge = {
  __typename?: 'MembershipEdge';
  node: Membership;
};

export type MembershipFilter = {
  /** Only return memberships that does *not* belong to super admins */
  excludeSuperAdmins?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only return active memberships (not pending, banned etc) */
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Only return memberships with one specific role
   * @deprecated use `roles`
   */
  role?: InputMaybe<Role>;
  /** Only return memberships with the specified roles */
  roles?: InputMaybe<Array<Role>>;
  /**
   * Only returns memberships with matching search term.
   * Currently only matches if the search term is contained in the users name.
   */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type MembershipOrder = {
  desc?: Scalars['Boolean']['input'];
  field: MembershipOrderField;
};

export const MembershipOrderField = {
  CreatedAt: 'CREATED_AT',
  Role: 'ROLE',
  UpdatedAt: 'UPDATED_AT',
  UserEmail: 'USER_EMAIL',
  UserName: 'USER_NAME'
} as const;

export type MembershipOrderField = typeof MembershipOrderField[keyof typeof MembershipOrderField];
export type MembershipRoomBookingFilter = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  onlyActiveBookings?: InputMaybe<Scalars['Boolean']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  timeWindow?: InputMaybe<TimeWindow>;
};

export type MembershipStats = {
  __typename?: 'MembershipStats';
  total: Scalars['Int']['output'];
};

export type MembershipsConnection = {
  __typename?: 'MembershipsConnection';
  edges: Array<MembershipEdge>;
  memberships: Array<Membership>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Activate a license for an organization. `id` can be either a license ID or
   * `"TRIAL"` to activate the trial period.
   */
  activateLicense: License;
  /**
   * Activate the overview code. This will bind the overview code to the
   * authenticated user and extend the expiration. Can be called multiple times to
   * extend the expiration. If the code can not be activated, one of the following
   * errors will be returned:
   * - `notFound`: the code do not exists or already activated by another user
   * - `expiredToken`: the auth request has expired
   * - `conflict`: if the request is already authorized
   */
  activateOverviewCode: ActivateOverviewCodePayload;
  addBooking: Booking;
  addBuilding: Building;
  addDesk: Desk;
  addDevice: Device;
  addFirmware: Firmware;
  addFloor: Floor;
  /**
   * Add ID tag for the given user. Only org. admins are allowed to add tags to other users.
   * User defaults to the subject.
   */
  addIDTag: IdTag;
  addInvitation: Invitation;
  addLicense: License;
  addOrganizationMessage: OrganizationMessage;
  addOverview: Overview;
  addRoom: Room;
  addRoomBooking: RoomBooking;
  /**
   * Assign firmware to one or more devices. Limit the assignment by `orgId`/`organizations`,
   * `devices` and `channels`. If `firmwareId` is not set, then the latest version for each
   * device will be assigned. Returns how many devices were updated.
   */
  assignFirmware: Scalars['Int']['output'];
  /** Single step authentication. Generates session token from domain + email + password. */
  authenticate: AuthenticatePayload;
  /**
   * Mutation to sign in and return API token by passing a valid OpenID Connect ID token.
   * If `signUp` is true, and the user does not already exist, a new user account is created.
   */
  authenticateWithIdToken: AuthenticatePayload;
  /**
   * Authenticate overview screen.
   * If the overview screen is not authorized yet, one of the following errors
   * will be returned:
   * - `notFound`: the device code do not exist
   * - `activationPending`: pending, the user has NOT started the user-interaction steps, continue polling
   * - `authorizationPending`: pending, the user has started the user-interaction steps, continue polling
   * - `slowDown`: polling to fast, increase interval by 5 seconds for this and all subsequent requests, continue polling
   * - `accessDenied`: the auth request has been rejected
   * - `expiredToken`: the auth request has expired
   */
  authenticateWithOverviewCode: AuthenticatePayload;
  /**
   * Endpoint for letting administrators authorize a screen to obtain a token to
   * show an overview screen. If authorization fails, one of the following errors
   * will be returned:
   * - `notFound`: the code do not exists or already activated by another user
   * - `expiredToken`: the auth request has expired
   * - `conflict`: if the request is already authorized
   */
  authorizeOverview: Overview;
  cancelBooking: Booking;
  cancelMembership: Membership;
  cancelRoomBooking: RoomBooking;
  checkInBooking: Booking;
  checkInRoomBooking: RoomBooking;
  checkOutBooking: Booking;
  checkOutRoomBooking: RoomBooking;
  createDevice: CreateDevicePayload;
  createDeviceToken: Scalars['String']['output'];
  createOrganization: Organization;
  createSpaceConnectOrganization: SpaceConnectOrganization;
  declineInvitation: Invitation;
  deleteBuilding: Building;
  deleteDesk: Desk;
  deleteFirmware: Firmware;
  deleteFloor: Floor;
  /** Remove ID tag */
  deleteIDTag: IdTag;
  deleteInvitation: Invitation;
  deleteMembership: Membership;
  deleteOrganization: Organization;
  deleteOrganizationMessage: OrganizationMessage;
  deleteOverview: Overview;
  deleteRoom: Room;
  deleteUser: User;
  /** @deprecated use 'updateBooking' instead */
  extendBooking: Booking;
  generateOverviewCode: OverviewCodePayload;
  joinOrganization: Membership;
  linkSpaceConnect: Organization;
  log: Scalars['Boolean']['output'];
  /**
   * Mutation to get a new refresh and access token. Once a refresh token is used,
   * it can not be used again.
   */
  refreshToken: RefreshTokenPayload;
  register: AuthenticatePayload;
  registerWithOIDC: AuthenticatePayload;
  requestReset: Scalars['Boolean']['output'];
  resendEmailVerificationLink: Scalars['Boolean']['output'];
  resetPassword: User;
  revokeLicense: License;
  revokeRefreshToken: RefreshToken;
  /** @deprecated Use register instead */
  signUp: SignUpPayload;
  supportRequest: Scalars['Boolean']['output'];
  unlinkSpaceConnect: Organization;
  updateBooking: Booking;
  updateBuilding: Building;
  updateDesk: Desk;
  updateDevice: Device;
  updateDeviceStatus: DeviceStatus;
  updateFeatures: Array<Scalars['String']['output']>;
  updateFirmware: Firmware;
  updateFloor: Floor;
  updateInvitation: Invitation;
  updateLicense: License;
  updateMembership: Membership;
  /**
   * Set the user ID that should be associated with a specific FCM token.
   * If user ID is null, the FCM token will be removed.
   */
  updateNotificationToken: Scalars['Boolean']['output'];
  updateOrganization: Organization;
  updateOrganizationMessage: OrganizationMessage;
  updateOverview: Overview;
  updateProfile: User;
  updateRoom: Room;
  updateRoomBooking: RoomBooking;
  verifyEmail: User;
};


export type MutationActivateLicenseArgs = {
  activationTime?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};


export type MutationActivateOverviewCodeArgs = {
  userCode: Scalars['String']['input'];
};


export type MutationAddBookingArgs = {
  idTag?: InputMaybe<Scalars['String']['input']>;
  input: AddBookingInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddBuildingArgs = {
  input: AddBuildingInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddDeskArgs = {
  input: AddDeskInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddDeviceArgs = {
  input: DeviceInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddFirmwareArgs = {
  input: AddFirmwareInput;
};


export type MutationAddFloorArgs = {
  buildingId: Scalars['ID']['input'];
  input: AddFloorInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddIdTagArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  tag: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationAddInvitationArgs = {
  input: AddInvitationInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddLicenseArgs = {
  input: AddLicenseInput;
};


export type MutationAddOrganizationMessageArgs = {
  input: AddOrganizationMessageInput;
};


export type MutationAddOverviewArgs = {
  input: AddOverviewInput;
};


export type MutationAddRoomArgs = {
  input: AddRoomInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAddRoomBookingArgs = {
  idTag?: InputMaybe<Scalars['String']['input']>;
  input: AddRoomBookingInput;
  orgId: Scalars['ID']['input'];
};


export type MutationAssignFirmwareArgs = {
  channels?: InputMaybe<Array<Scalars['ID']['input']>>;
  devices?: InputMaybe<Array<Scalars['ID']['input']>>;
  firmwareId?: InputMaybe<Scalars['ID']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  organizations?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationAuthenticateArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationAuthenticateWithIdTokenArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  idToken: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  signUp?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationAuthenticateWithOverviewCodeArgs = {
  deviceCode: Scalars['String']['input'];
};


export type MutationAuthorizeOverviewArgs = {
  orgId: Scalars['ID']['input'];
  overviewId: Scalars['String']['input'];
  userCode: Scalars['String']['input'];
};


export type MutationCancelBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelMembershipArgs = {
  orgId: Scalars['ID']['input'];
};


export type MutationCancelRoomBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
};


export type MutationCheckInBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCheckInRoomBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCheckOutBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCheckOutRoomBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
};


export type MutationCreateDeviceArgs = {
  input: DeviceInput;
  orgId: Scalars['ID']['input'];
};


export type MutationCreateDeviceTokenArgs = {
  deviceId: Scalars['ID']['input'];
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateSpaceConnectOrganizationArgs = {
  input: CreateSpaceConnectOrganizationInput;
};


export type MutationDeclineInvitationArgs = {
  domain: Scalars['String']['input'];
};


export type MutationDeleteBuildingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDeskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFirmwareArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFloorArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteIdTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInvitationArgs = {
  email: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
};


export type MutationDeleteMembershipArgs = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationMessageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOverviewArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRoomArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationExtendBookingArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  seconds?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationJoinOrganizationArgs = {
  domain: Scalars['String']['input'];
};


export type MutationLinkSpaceConnectArgs = {
  orgId: Scalars['ID']['input'];
  spaceConnectId: Scalars['ID']['input'];
};


export type MutationLogArgs = {
  input: LogInput;
};


export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationRegisterWithOidcArgs = {
  input: RegisterWithOidcInput;
};


export type MutationRequestResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationResendEmailVerificationLinkArgs = {
  email: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRevokeLicenseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRevokeRefreshTokenArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSupportRequestArgs = {
  formData: Scalars['Map']['input'];
  topic: SupportTopic;
};


export type MutationUnlinkSpaceConnectArgs = {
  orgId: Scalars['ID']['input'];
};


export type MutationUpdateBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  input: UpdateBookingInput;
};


export type MutationUpdateBuildingArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBuildingInput;
};


export type MutationUpdateDeskArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDeskInput;
};


export type MutationUpdateDeviceArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDeviceInput;
};


export type MutationUpdateDeviceStatusArgs = {
  id: Scalars['ID']['input'];
  input: DeviceStatusInput;
};


export type MutationUpdateFeaturesArgs = {
  features: Array<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
};


export type MutationUpdateFirmwareArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFirmwareInput;
};


export type MutationUpdateFloorArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFloorInput;
};


export type MutationUpdateInvitationArgs = {
  email: Scalars['String']['input'];
  input: UpdateInvitationInput;
  orgId: Scalars['ID']['input'];
};


export type MutationUpdateLicenseArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLicenseInput;
};


export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput;
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUpdateNotificationTokenArgs = {
  fcmToken: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationMessageArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrganizationMessageInput;
};


export type MutationUpdateOverviewArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOverviewInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateRoomArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRoomInput;
};


export type MutationUpdateRoomBookingArgs = {
  id: Scalars['ID']['input'];
  idTag?: InputMaybe<Scalars['String']['input']>;
  input: UpdateRoomBookingInput;
  orgId: Scalars['ID']['input'];
};


export type MutationVerifyEmailArgs = {
  token: Scalars['String']['input'];
};

export type OpenHours = {
  __typename?: 'OpenHours';
  closed: Scalars['Boolean']['output'];
  from: Scalars['TimeOfDay']['output'];
  until: Scalars['TimeOfDay']['output'];
};

export type OpenHoursInput = {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['TimeOfDay']['input']>;
  until?: InputMaybe<Scalars['TimeOfDay']['input']>;
};

export type Organization = {
  __typename?: 'Organization';
  bookingSettings: OrganizationBookingSettings;
  createdAt: Scalars['DateTime']['output'];
  deskBookingSettings: DeskBookingSettings;
  deskStats: DeskStats;
  deviceStats: DeviceStats;
  domain: Scalars['String']['output'];
  /** A list of feature flags for this organization. */
  features: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /**
   * If true, desk-booking is only enabled from Kleeo devices. Support
   * for showing and booking desks in the app will be disabled.
   */
  kleeoBookingOnly: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  licenseStats: LicenseStats;
  logoMediaId?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  membershipStats: MembershipStats;
  name: Scalars['String']['output'];
  ola: Scalars['String']['output'];
  openHours: WeeklyOpenHours;
  roomBookingRegistrationSentAt?: Maybe<Scalars['DateTime']['output']>;
  roomBookingSettings: RoomBookingSettings;
  /** If true, room booking will be enabled in the app */
  roomBookingSoftwareLicense: Scalars['Boolean']['output'];
  spaceConnectId?: Maybe<Scalars['ID']['output']>;
  timePresentation: TimePresentation;
  timezone: Scalars['String']['output'];
  trial?: Maybe<License>;
  updatedAt: Scalars['DateTime']['output'];
  whitelist?: Maybe<Scalars['String']['output']>;
  whitelistEnabled: Scalars['Boolean']['output'];
};

export type OrganizationBookingSettings = {
  __typename?: 'OrganizationBookingSettings';
  privateOnly: Scalars['Boolean']['output'];
};

export type OrganizationBookingSettingsInput = {
  privateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  node: Organization;
};

export type OrganizationFilter = {
  onlyActiveTrial?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationMessage = {
  __typename?: 'OrganizationMessage';
  canBeClosed: Scalars['Boolean']['output'];
  content: Scalars['String']['output'];
  hideAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  organizations: Array<Organization>;
  showAt?: Maybe<Scalars['DateTime']['output']>;
  showToAllOrganizations: Scalars['Boolean']['output'];
  type: OrganizationMessageType;
};

export type OrganizationMessageEdge = {
  __typename?: 'OrganizationMessageEdge';
  node: OrganizationMessage;
};

export type OrganizationMessageFilter = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

export type OrganizationMessageOrder = {
  desc?: Scalars['Boolean']['input'];
  field: OrganizationMessageOrderField;
};

export const OrganizationMessageOrderField = {
  CanBeClosed: 'CAN_BE_CLOSED',
  Content: 'CONTENT',
  HideAt: 'HIDE_AT',
  ShowAt: 'SHOW_AT',
  Type: 'TYPE'
} as const;

export type OrganizationMessageOrderField = typeof OrganizationMessageOrderField[keyof typeof OrganizationMessageOrderField];
export const OrganizationMessageType = {
  Error: 'ERROR',
  Info: 'INFO',
  Success: 'SUCCESS',
  Warning: 'WARNING'
} as const;

export type OrganizationMessageType = typeof OrganizationMessageType[keyof typeof OrganizationMessageType];
export type OrganizationMessagesConnection = {
  __typename?: 'OrganizationMessagesConnection';
  edges: Array<OrganizationMessageEdge>;
  organizationMessages: Array<OrganizationMessage>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrganizationOrder = {
  desc?: Scalars['Boolean']['input'];
  field: OrganizationOrderField;
};

export const OrganizationOrderField = {
  CreatedAt: 'CREATED_AT',
  DeskStatsTotal: 'DESK_STATS_TOTAL',
  DeviceStatsTotal: 'DEVICE_STATS_TOTAL',
  Domain: 'DOMAIN',
  MembershipStatsTotal: 'MEMBERSHIP_STATS_TOTAL',
  Name: 'NAME',
  Ola: 'OLA',
  SpaceConnectId: 'SPACE_CONNECT_ID',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type OrganizationOrderField = typeof OrganizationOrderField[keyof typeof OrganizationOrderField];
export type OrganizationsConnection = {
  __typename?: 'OrganizationsConnection';
  edges: Array<OrganizationEdge>;
  organizations: Array<Organization>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Overview = {
  __typename?: 'Overview';
  columnCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  floor?: Maybe<Floor>;
  id: Scalars['ID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  showDateTime: Scalars['Boolean']['output'];
  showLogo: Scalars['Boolean']['output'];
  tiles: Array<Tile>;
  type: OverviewType;
  updatedAt: Scalars['DateTime']['output'];
};

export type OverviewCodePayload = {
  __typename?: 'OverviewCodePayload';
  deviceCode: Scalars['String']['output'];
  expiresIn: Scalars['Duration']['output'];
  interval: Scalars['Duration']['output'];
  userCode: Scalars['String']['output'];
  verificationUri: Scalars['String']['output'];
  verificationUriComplete?: Maybe<Scalars['String']['output']>;
};

export type OverviewEdge = {
  __typename?: 'OverviewEdge';
  node: Overview;
};

export type OverviewFilter = {
  /** Checks if `name` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type OverviewOrder = {
  desc?: Scalars['Boolean']['input'];
  field: OverviewOrderField;
};

export const OverviewOrderField = {
  CreatedAt: 'CREATED_AT',
  Name: 'NAME',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type OverviewOrderField = typeof OverviewOrderField[keyof typeof OverviewOrderField];
export const OverviewType = {
  Floorplan: 'FLOORPLAN',
  Tiles: 'TILES'
} as const;

export type OverviewType = typeof OverviewType[keyof typeof OverviewType];
export type OverviewsConnection = {
  __typename?: 'OverviewsConnection';
  edges: Array<OverviewEdge>;
  overviews: Array<Overview>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  allBookings: BookingsConnection;
  allBuildings: BuildingsConnection;
  allDesks: DesksConnection;
  allDevices: DevicesConnection;
  allFirmwares: FirmwaresConnection;
  allFloors: FloorsConnection;
  allIDTags: IdTagsConnection;
  allInvitations: InvitationsConnection;
  allLicenses: LicensesConnection;
  allMemberships: MembershipsConnection;
  allOrganizationMessages: OrganizationMessagesConnection;
  allOrganizations: OrganizationsConnection;
  allOverviews: OverviewsConnection;
  allRoomBookings: RoomBookingsConnection;
  allRooms: RoomsConnection;
  /** Returns list of all users */
  allUsers: UsersConnection;
  building: Building;
  channels: Array<Channel>;
  checkAvailability: Scalars['Boolean']['output'];
  desk?: Maybe<Desk>;
  device: Device;
  floor: Floor;
  /** @deprecated Use licenseStats on Organization instead. */
  licenseStats: LicenseStats;
  membership: Membership;
  organization: Organization;
  overview?: Maybe<Overview>;
  profile?: Maybe<User>;
  room: Room;
  spaceConnect: SpaceConnectNamespace;
  suggestedDesks: DesksConnection;
};


export type QueryAllBookingsArgs = {
  filter?: InputMaybe<BookingFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<BookingOrder>>;
};


export type QueryAllBuildingsArgs = {
  filter?: InputMaybe<BuildingFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<BuildingOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllDesksArgs = {
  filter?: InputMaybe<DeskFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<DeskOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllDevicesArgs = {
  filter?: InputMaybe<DeviceFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyActivated?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Array<DeviceOrder>>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllFirmwaresArgs = {
  channelId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<FirmwareFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<FirmwareOrder>>;
};


export type QueryAllFloorsArgs = {
  filter?: InputMaybe<FloorFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<FloorOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllIdTagsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IdTagOrder>>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllInvitationsArgs = {
  filter?: InputMaybe<InvitationFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InvitationOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllLicensesArgs = {
  filter?: InputMaybe<LicenseFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<LicenseOrder>>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllMembershipsArgs = {
  filter?: InputMaybe<MembershipFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<MembershipOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllOrganizationMessagesArgs = {
  filter?: InputMaybe<OrganizationMessageFilter>;
  limit?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrganizationMessageOrder>>;
};


export type QueryAllOrganizationsArgs = {
  filter?: InputMaybe<OrganizationFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrganizationOrder>>;
};


export type QueryAllOverviewsArgs = {
  filter?: InputMaybe<OverviewFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OverviewOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllRoomBookingsArgs = {
  filter: RoomBookingFilter;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};


export type QueryAllRoomsArgs = {
  filter?: InputMaybe<RoomFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<RoomOrder>>;
  orgId: Scalars['ID']['input'];
};


export type QueryAllUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<UserOrder>>;
};


export type QueryBuildingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCheckAvailabilityArgs = {
  email: Scalars['String']['input'];
};


export type QueryDeskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDeviceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFloorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLicenseStatsArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryMembershipArgs = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOverviewArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRoomArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySuggestedDesksArgs = {
  filter?: InputMaybe<DeskFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type RefreshToken = {
  __typename?: 'RefreshToken';
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  refreshedAt: Scalars['DateTime']['output'];
  revokeReason?: Maybe<Scalars['String']['output']>;
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The Subject of this refresh token. `null` if the subject is not found. */
  subject?: Maybe<Subject>;
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  accessToken: Scalars['String']['output'];
  /** Duration until the accessToken expires */
  expiresIn: Scalars['Duration']['output'];
  refreshToken: Scalars['String']['output'];
};

export type RegisterInput = {
  email: Scalars['String']['input'];
  /** @deprecated Field no longer supported */
  eula?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegisterWithOidcInput = {
  /** Optional email in case email is not available in idToken */
  email?: InputMaybe<Scalars['String']['input']>;
  eula: Scalars['String']['input'];
  idToken: Scalars['String']['input'];
  /** Optional name in case name is not available in idToken */
  name?: InputMaybe<Scalars['String']['input']>;
};

export const Role = {
  Anonymous: 'ANONYMOUS',
  Banned: 'BANNED',
  DeskAdmin: 'DESK_ADMIN',
  OrgAdmin: 'ORG_ADMIN',
  Owner: 'OWNER',
  Pending: 'PENDING',
  SuperAdmin: 'SUPER_ADMIN',
  User: 'USER'
} as const;

export type Role = typeof Role[keyof typeof Role];
export type Room = {
  __typename?: 'Room';
  /**
   * Contains the available timespan for this room within the requested period.
   * Note: this is always `null` if not requested with `AvailableFilter`, e.g.
   * using `allRooms` query.
   */
  available?: Maybe<Availability>;
  bookingSettings: RoomBookingSettings;
  capacity: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The currently active booking, if any */
  currentBooking?: Maybe<RoomBooking>;
  email: Scalars['String']['output'];
  floor?: Maybe<Floor>;
  floorId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  managedRoom: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** The next upcoming booking, if any */
  nextBooking?: Maybe<RoomBooking>;
  orgId: Scalars['ID']['output'];
  resources: Array<RoomResource>;
  roomType: RoomType;
  /**
   * Time presentation used for this room. The value is picked from the building
   * with the organization default as fallback.
   */
  timePresentation: TimePresentation;
  /**
   * Time zone of this room. The time zone is picked from the building with
   * the organization default as fallback.
   */
  timeZone: Scalars['String']['output'];
  /** Time offset from UTC in seconds */
  timeZoneOffset: Scalars['Int']['output'];
  /** @deprecated use roomType */
  type: RoomType;
  updatedAt: Scalars['DateTime']['output'];
};

export type RoomBooking = {
  __typename?: 'RoomBooking';
  attendees: Array<Attendee>;
  bookedBy: Scalars['String']['output'];
  canceled?: Maybe<Scalars['DateTime']['output']>;
  checkedIn?: Maybe<Scalars['DateTime']['output']>;
  checkedOut?: Maybe<Scalars['DateTime']['output']>;
  endRecurringDate?: Maybe<Scalars['DateTime']['output']>;
  endTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  recurring?: Maybe<Scalars['String']['output']>;
  room?: Maybe<Room>;
  startTime: Scalars['DateTime']['output'];
  teamsMeeting?: Maybe<Scalars['Boolean']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  user?: Maybe<User>;
};

export type RoomBookingEdge = {
  __typename?: 'RoomBookingEdge';
  node: RoomBooking;
};

export type RoomBookingEvent = {
  __typename?: 'RoomBookingEvent';
  added?: Maybe<RoomBooking>;
  deleted?: Maybe<RoomBooking>;
  updated?: Maybe<RoomBooking>;
};

export type RoomBookingFilter = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  onlyActiveBookings?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
  roomId?: InputMaybe<Scalars['ID']['input']>;
  timeWindow?: InputMaybe<TimeWindow>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type RoomBookingSettings = {
  __typename?: 'RoomBookingSettings';
  /** If true, the desk device will automatically check-in a current booking on presence detection */
  autoCheckInOnPresence: Scalars['Boolean']['output'];
  autoReleaseOnNoShow: Scalars['Boolean']['output'];
  bookingWindowEnds: Scalars['TimeOfDay']['output'];
  bookingWindowStarts: Scalars['TimeOfDay']['output'];
  /** How many calendar days in advance you may place a booking */
  daysInAdvanceLimit: Scalars['Int']['output'];
  durationLimit: Scalars['Duration']['output'];
  /** Period of time, before start of booking, check-in is allowed. */
  earlyCheckIn: Scalars['Duration']['output'];
  /** The initial/default booking duration */
  initialDuration: Scalars['Duration']['output'];
  /** If true, the booker of a booking is private for all bookings. */
  privateOnly: Scalars['Boolean']['output'];
  /** If true, user are allowed to check-in using qr code in the app. */
  qrCodeCheckIn: Scalars['Boolean']['output'];
  /** If true, users are allowed to check-in remotely using the app */
  remoteCheckIn: Scalars['Boolean']['output'];
  /**
   * If true, the room device will require identification with RFID tag to allow
   * the user to book the desk.
   */
  requireIdentForBooking: Scalars['Boolean']['output'];
  /** If true, the user must identify with RFID tag to check in on the room device */
  requireIdentForCheckIn: Scalars['Boolean']['output'];
  /** If true, the user must identify with RFID tag to extend or check-out a booking */
  requireIdentForUpdating: Scalars['Boolean']['output'];
};

export type RoomBookingSettingsInput = {
  autoCheckInOnPresence?: InputMaybe<Scalars['Boolean']['input']>;
  autoReleaseOnNoShow?: InputMaybe<Scalars['Boolean']['input']>;
  bookingWindowEnds?: InputMaybe<Scalars['TimeOfDay']['input']>;
  bookingWindowStarts?: InputMaybe<Scalars['TimeOfDay']['input']>;
  daysInAdvanceLimit?: InputMaybe<Scalars['Int']['input']>;
  durationLimit?: InputMaybe<Scalars['Duration']['input']>;
  earlyCheckIn?: InputMaybe<Scalars['Duration']['input']>;
  initialDuration?: InputMaybe<Scalars['Duration']['input']>;
  qrCodeCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  remoteCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForBooking?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  requireIdentForUpdating?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoomBookingsConnection = {
  __typename?: 'RoomBookingsConnection';
  bookings: Array<RoomBooking>;
  edges: Array<RoomBookingEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RoomEdge = {
  __typename?: 'RoomEdge';
  node: Room;
};

export type RoomFilter = {
  /** Note: this filter can not be combined with `RoomOrder` when querying. */
  available?: InputMaybe<AvailableFilter>;
  floors?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Checks if `name` or `email` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type RoomOrder = {
  desc?: Scalars['Boolean']['input'];
  field: RoomOrderField;
};

export const RoomOrderField = {
  Capacity: 'CAPACITY',
  CreatedAt: 'CREATED_AT',
  Email: 'EMAIL',
  Name: 'NAME',
  UpdatedAt: 'UPDATED_AT'
} as const;

export type RoomOrderField = typeof RoomOrderField[keyof typeof RoomOrderField];
export const RoomResource = {
  AirConditioning: 'AIR_CONDITIONING',
  Computer: 'COMPUTER',
  EvokoGroupie: 'EVOKO_GROUPIE',
  EvokoMinto: 'EVOKO_MINTO',
  Lights: 'LIGHTS',
  Other: 'OTHER',
  Phone: 'PHONE',
  Projector: 'PROJECTOR',
  Screen: 'SCREEN',
  TeleConference: 'TELE_CONFERENCE',
  VideoConference: 'VIDEO_CONFERENCE',
  Webcam: 'WEBCAM',
  Whiteboard: 'WHITEBOARD',
  Wifi: 'WIFI'
} as const;

export type RoomResource = typeof RoomResource[keyof typeof RoomResource];
export const RoomType = {
  MeetingRoom: 'MEETING_ROOM',
  None: 'NONE',
  TrainingRoom: 'TRAINING_ROOM'
} as const;

export type RoomType = typeof RoomType[keyof typeof RoomType];
export type RoomsConnection = {
  __typename?: 'RoomsConnection';
  edges: Array<RoomEdge>;
  pageInfo: PageInfo;
  rooms: Array<Room>;
  totalCount: Scalars['Int']['output'];
};

/** Deprecated: see signUp */
export type SignUpInput = {
  email: Scalars['String']['input'];
  /** @deprecated Field no longer supported */
  eula?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  accessToken: Scalars['String']['output'];
  /** Duration until the accessToken expires */
  expiresIn: Scalars['Duration']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  user: User;
};

export type SpaceConnectNamespace = {
  __typename?: 'SpaceConnectNamespace';
  organization: SpaceConnectOrganization;
  organizations: Array<SpaceConnectOrganization>;
};


export type SpaceConnectNamespaceOrganizationArgs = {
  id: Scalars['ID']['input'];
};

export type SpaceConnectOrganization = {
  __typename?: 'SpaceConnectOrganization';
  address?: Maybe<Scalars['String']['output']>;
  administratorEmail?: Maybe<Scalars['String']['output']>;
  administratorName?: Maybe<Scalars['String']['output']>;
  administratorPhone?: Maybe<Scalars['String']['output']>;
  domains?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Subject = Device | Overview | User;

export type Subscription = {
  __typename?: 'Subscription';
  bookings: BookingEvent;
  deskUpdate: Desk;
  deviceUpdate: Device;
  roomBookings: RoomBookingEvent;
  roomUpdate: Room;
};


export type SubscriptionBookingsArgs = {
  deskId: Scalars['ID']['input'];
};


export type SubscriptionDeskUpdateArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionDeviceUpdateArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionRoomBookingsArgs = {
  roomId: Scalars['ID']['input'];
};


export type SubscriptionRoomUpdateArgs = {
  id: Scalars['ID']['input'];
};

export const SupportTopic = {
  SpecialSignUp: 'specialSignUp'
} as const;

export type SupportTopic = typeof SupportTopic[keyof typeof SupportTopic];
/** This represents a tile for an overview screen */
export type Tile = {
  __typename?: 'Tile';
  directionIndicator?: Maybe<Direction>;
  floor?: Maybe<Floor>;
  floorId?: Maybe<Scalars['ID']['output']>;
  hideBookingInformation?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  room?: Maybe<Room>;
  roomId?: Maybe<Scalars['ID']['output']>;
  type: TileType;
};

export const TileType = {
  Blank: 'BLANK',
  Desk: 'DESK',
  Room: 'ROOM'
} as const;

export type TileType = typeof TileType[keyof typeof TileType];
export const TimePresentation = {
  H12: 'H12',
  H24: 'H24'
} as const;

export type TimePresentation = typeof TimePresentation[keyof typeof TimePresentation];
export type TimeWindow = {
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
};

export type UpdateBookingInput = {
  arrivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  deskId?: InputMaybe<Scalars['ID']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  focusMode?: InputMaybe<Scalars['Boolean']['input']>;
  skipAwayFromDeskReminder?: InputMaybe<Scalars['Boolean']['input']>;
  skipLateArrivalReminder?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateBuildingInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openHours?: InputMaybe<WeeklyOpenHoursInput>;
  overrideOpenHours?: InputMaybe<Scalars['Boolean']['input']>;
  timePresentation?: InputMaybe<TimePresentation>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDeskInput = {
  deskType?: InputMaybe<DeskType>;
  floorId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  presenceDetection?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDeviceInput = {
  channelId?: InputMaybe<Scalars['ID']['input']>;
  deskId?: InputMaybe<Scalars['ID']['input']>;
  flashId?: InputMaybe<Scalars['String']['input']>;
  mac?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFirmwareInput = {
  channels?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** @deprecated no longer used */
  hash?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFloorInput = {
  floorplan?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateInvitationInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  extendExpiration?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Role>;
};

export type UpdateLicenseInput = {
  activationTime?: InputMaybe<Scalars['DateTime']['input']>;
  desks?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
  rooms?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateMembershipInput = {
  role?: InputMaybe<Role>;
  spaceConnectEmail?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationInput = {
  bookingSettings?: InputMaybe<OrganizationBookingSettingsInput>;
  deskBookingSettings?: InputMaybe<DeskBookingSettingsInput>;
  domain?: InputMaybe<Scalars['String']['input']>;
  kleeoBookingOnly?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  logoMediaId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openHours?: InputMaybe<WeeklyOpenHoursInput>;
  roomBookingSettings?: InputMaybe<RoomBookingSettingsInput>;
  roomBookingSoftwareLicense?: InputMaybe<Scalars['Boolean']['input']>;
  timePresentation?: InputMaybe<TimePresentation>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  whitelist?: InputMaybe<Scalars['String']['input']>;
  whitelistEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateOrganizationMessageInput = {
  canBeClosed?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  hideAt?: InputMaybe<Scalars['DateTime']['input']>;
  organizations?: InputMaybe<Array<Scalars['ID']['input']>>;
  showAt?: InputMaybe<Scalars['DateTime']['input']>;
  showToAllOrganizations?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<OrganizationMessageType>;
};

export type UpdateOverviewInput = {
  columnCount?: InputMaybe<Scalars['Int']['input']>;
  floorId?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  showDateTime?: InputMaybe<Scalars['Boolean']['input']>;
  showLogo?: InputMaybe<Scalars['Boolean']['input']>;
  tiles?: InputMaybe<Array<InputMaybe<UpdateTileInput>>>;
  type?: InputMaybe<OverviewType>;
};

export type UpdateRoomBookingInput = {
  /** attendees is currently ignored when updating a booking. */
  attendees?: InputMaybe<Array<AttendeeInput>>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoomInput = {
  capacity?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  floorId?: InputMaybe<Scalars['ID']['input']>;
  managedRoom?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Array<RoomResource>>;
  type?: InputMaybe<RoomType>;
};

export type UpdateTileInput = {
  directionIndicator?: InputMaybe<Direction>;
  floorId?: InputMaybe<Scalars['ID']['input']>;
  hideBookingInformation?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  roomId?: InputMaybe<Scalars['ID']['input']>;
  type: TileType;
};

export type UpdateUserInput = {
  eula?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  bookings: Array<Booking>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  eula?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitations: Array<Invitation>;
  memberships: Array<Membership>;
  name: Scalars['String']['output'];
  superAdmin: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  verified: Scalars['Boolean']['output'];
};


export type UserBookingsArgs = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
};


export type UserInvitationsArgs = {
  filter?: InputMaybe<InvitationFilter>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  node: User;
};

export type UserFilter = {
  /** Only returns users where `name` or `email` contains the search term. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserOrder = {
  desc?: Scalars['Boolean']['input'];
  field: UserOrderField;
};

export const UserOrderField = {
  CreatedAt: 'CREATED_AT',
  Email: 'EMAIL',
  Eula: 'EULA',
  Name: 'NAME',
  SuperAdmin: 'SUPER_ADMIN',
  UpdatedAt: 'UPDATED_AT',
  Verified: 'VERIFIED'
} as const;

export type UserOrderField = typeof UserOrderField[keyof typeof UserOrderField];
export type UsersConnection = {
  __typename?: 'UsersConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  users: Array<User>;
};

export type WeeklyOpenHours = {
  __typename?: 'WeeklyOpenHours';
  fri: OpenHours;
  mon: OpenHours;
  sat: OpenHours;
  sun: OpenHours;
  thu: OpenHours;
  tue: OpenHours;
  wed: OpenHours;
};

export type WeeklyOpenHoursInput = {
  fri?: InputMaybe<OpenHoursInput>;
  mon?: InputMaybe<OpenHoursInput>;
  sat?: InputMaybe<OpenHoursInput>;
  sun?: InputMaybe<OpenHoursInput>;
  thu?: InputMaybe<OpenHoursInput>;
  tue?: InputMaybe<OpenHoursInput>;
  wed?: InputMaybe<OpenHoursInput>;
};

export type JoinOrganizationMutationVariables = Exact<{
  domain: Scalars['String']['input'];
}>;


export type JoinOrganizationMutation = { __typename?: 'Mutation', joinOrganization: { __typename?: 'Membership', orgId: string, userId: string } };

export type MembershipFieldsFragment = { __typename?: 'Membership', role: Role, userId: string, orgId: string, orgName: string, orgLogoUrl?: string | null, organization?: { __typename?: 'Organization', domain: string } | null };

export type InvitationFieldsFragment = { __typename?: 'Invitation', orgId: string, orgName: string, orgDomain: string, orgLogoUrl?: string | null, email: string, role: Role, status: InvitationStatus };

export type ProfileFieldsFragment = { __typename?: 'User', name: string, email: string, verified: boolean, superAdmin: boolean, memberships: Array<{ __typename?: 'Membership', role: Role, userId: string, orgId: string, orgName: string, orgLogoUrl?: string | null, organization?: { __typename?: 'Organization', domain: string } | null }>, invitations: Array<{ __typename?: 'Invitation', orgId: string, orgName: string, orgDomain: string, orgLogoUrl?: string | null, email: string, role: Role, status: InvitationStatus }> };

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = { __typename?: 'Query', profile?: { __typename?: 'User', name: string, email: string, verified: boolean, superAdmin: boolean, memberships: Array<{ __typename?: 'Membership', role: Role, userId: string, orgId: string, orgName: string, orgLogoUrl?: string | null, organization?: { __typename?: 'Organization', domain: string } | null }>, invitations: Array<{ __typename?: 'Invitation', orgId: string, orgName: string, orgDomain: string, orgLogoUrl?: string | null, email: string, role: Role, status: InvitationStatus }> } | null };

export type RequestResetMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type RequestResetMutation = { __typename?: 'Mutation', requestReset: boolean };

export type DeclineInvitationMutationVariables = Exact<{
  domain: Scalars['String']['input'];
}>;


export type DeclineInvitationMutation = { __typename?: 'Mutation', declineInvitation: { __typename?: 'Invitation', orgId: string, email: string } };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'User', email: string } };

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail: { __typename?: 'User', id: string, name: string, verified: boolean } };

export type ResendEmailVerificationLinkMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResendEmailVerificationLinkMutation = { __typename?: 'Mutation', resendEmailVerificationLink: boolean };
